import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import CFS from '../components/molecules/CFS';
import Checkmark from '../components/atoms/Checkmark';
import Collapse from '../components/molecules/Collapse';
import imgSecSection from '../assets/images/pages/que-significa-ele/secondImg.png';
import iInclude from '../assets/images/checkmark-include.svg';
import imgCTA from '../assets/images/pages/que-significa-ele/firstImg.png';
import '../assets/styles/pages/que-significa-ele.scss';

const list2 = `
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Podrás ampliar conocimientos previos y aprender nuevas competencias ELE.</p>
    </div>
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Aprendemos haciendo: <strong>10 horas</strong> de clases prácticas con estudiantes reales.</p>
    </div>
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Observarás a profesores profesionales.</p>
    </div>
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Talleres formativos a la medida de tus necesidades.</p>
    </div>
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Grupos reducidos e instalaciones modernas en una escuela de verdad.</p>
    </div>
`;
const list1 = `
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Podrás enseñar español en tu propio país o en cualquier parte del mundo.</p>
    </div>
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Aprendemos haciendo: <strong>40 horas </strong> de clases prácticas con estudiantes reales.</p>
    </div>
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Observarás a profesores noveles y profesionales.</p>
    </div>
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Ofrecemos un servicio de orientación profesional para asesorarte en tu futuro.</p>
    </div>
    <div class="tick">
    <img class="tick__mark" src=${iInclude} alt="checkmark" />
    <p class="tick__body c-text-15">Grupos reducidos e instalaciones modernas en una escuela de verdad.
    </p>
    </div>
`;

const QueSignificaEle = () => (
  <Layout
    title="¿Qué Significa ELE? | ELE Barcelona"
    description="Las siglas ELE significan Español como Lengua Extranjera y se utiliza entre especialistas de la enseñanza del español a hablantes nativos de otras lenguas."
  >
    <div className="que-significa-ele">
      <CFS title="¿Qué significa ELE?" />
      <section className="second-section c-faqs-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <h3 className="left__title c-title-34">Sobre ELE</h3>
                <p className="left__description c-text-16">
                  <span className="text-fw-600">Definición oficial del Instituto Cervantes:</span>
                  <br />
                  <br />
                  El acrónimo ELE significa ‘español como lengua extranjera’ y se emplea
                  habitualmente entre los profesores, investigadores, creadores de materiales y
                  demás especialistas de la enseñanza del español a hablantes nativos de otras
                  lenguas (extranjeros). <br />
                  <br />
                  ELE es el término que usamos para referirnos a la enseñanza y el aprendizaje del
                  español a estudiantes extranjeros.
                  <br />
                  Junto a ELE, otra sigla es FELE, que añade una “F” de “formación” y se usa para
                  simplificar el nombre de la “Formación de profesores de español como lengua
                  extranjera”. FELE es el nombre de nuestro curso estrella para convertirse en
                  profesor o profesora de español. Leé más sobre terminología ELE en nuestro blog.
                  <br />
                  <br />
                  <span className="c-text-16 c-text-16--gray-100">
                    En definitiva, ELE puede tener el significado que tú le quieras dar. Un
                    pasaporte para poder viajar a países extranjeros y trabajar en algo que te gusta
                    al mismo tiempo, una nueva oportunidad, una nueva profesión o una nueva vida.
                  </span>
                </p>
                <Collapse title="¿Por qué estudiar el FELE?" description={list1} />
                <Collapse title="¿Cómo me hago profesor de ELE?" description={list2} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right">
                <img className="cta-img" src={imgCTA} alt="imgCTA" />
                <div className="overlap">
                  <p>Española</p>
                  <p>Lengua</p>
                  <p>Extranjera</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="third-section c-faqs-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="right">
                <h3 className="right__title c-title-34">¿Qué curso debería hacer?</h3>
                <div className="wrapper">
                  <Collapse
                    title="Si quieres hacerte profesor ELE, aquí tienes algunas de las razones por las que deberías hacer el curso intensivo de 4 semanas - <span class='colored no-link'>FELE</span>:"
                    description={list1}
                  />
                  <Collapse
                    title="Si ya eres profesor y quieres continuar con tu formación profesional, aquí tienes algunas de las razones por las que deberías hacer el curso intensivo <span class='colored no-link'>ELE+</span> de 1 semana:
                    "
                    description={list2}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="left">
                <img src={imgSecSection} alt="Happy person" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default QueSignificaEle;
